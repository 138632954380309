import { Link, StaticQuery, graphql } from "gatsby"
import PreviewCompatibleImage from "./PreviewCompatibleImage"
import React from "react"

interface PropType {
    data: {
        allMarkdownRemark: {
            edges: any[]
        }
    }
}

const BlogRoll: React.FC<PropType> = ({ data }) => {
    const { edges: posts } = data.allMarkdownRemark

    return (
        <div className="columns is-multiline">
            {posts &&
                posts.map(({ node: post }) => (
                    <div className="is-parent column is-6" key={post.id}>
                        <article
                            className={`blog-list-item tile is-child box notification ${
                                post.frontmatter.featuredpost
                                    ? "is-featured"
                                    : ""
                            }`}
                        >
                            <header>
                                {post.frontmatter.featuredimage ? (
                                    <div className="featured-thumbnail">
                                        <PreviewCompatibleImage
                                            imageInfo={{
                                                image:
                                                    post.frontmatter
                                                        .featuredimage,
                                                alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                                            }}
                                        />
                                    </div>
                                ) : null}
                                <p className="post-meta">
                                    <Link
                                        className="title has-text-primary is-size-4"
                                        to={post.fields.slug}
                                    >
                                        {post.frontmatter.title}
                                    </Link>
                                    <span> &bull; </span>
                                    <span className="subtitle is-size-5 is-block">
                                        {post.frontmatter.date}
                                    </span>
                                </p>
                            </header>
                            <p>
                                {post.excerpt}
                                <br />
                                <br />
                                <Link className="button" to={post.fields.slug}>
                                    Keep Reading →
                                </Link>
                            </p>
                        </article>
                    </div>
                ))}
        </div>
    )
}

// eslint-disable-next-line react/display-name
export default () => (
    <StaticQuery
        query={graphql`
            query BlogRollQuery {
                allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] }
                    filter: {
                        frontmatter: { templateKey: { eq: "blog-post" } }
                    }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 400)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                templateKey
                                date(formatString: "MMMM DD, YYYY")
                                featuredpost
                                featuredimage {
                                    childImageSharp {
                                        fluid(maxWidth: 300, quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                    extension
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => <BlogRoll data={data} />}
    />
)
